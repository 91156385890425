<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1>健康笔记 支持</h1>
    <h3 style="color: #8CCC74; padding-bottom: 100px;" >Health Note Support</h3>
    <div class="card-container">
      <Card  title="这是什么" description="健康笔记App — 是一款记录你的病史，不论大病小病，记录报告的App，健康笔记跟您一样关心您的健康。"
      link="https://healthnote.dmxx.ltd/" linkText="我要使用"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
.logoimg {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: #8CCC74;
  padding-top: 100px;
}
</style>